import { AffineContext } from '@affine/component/context';
import { GlobalLoading } from '@affine/component/global-loading';
import { AppFallback } from '@affine/core/components/affine/app-container';
import { Telemetry } from '@affine/core/components/telemetry';
import { router } from '@affine/core/desktop/router';
import { configureCommonModules } from '@affine/core/modules';
import { AuthService, FetchService } from '@affine/core/modules/cloud';
import { configureLocalStorageStateStorageImpls } from '@affine/core/modules/storage';
import { CustomThemeModifier } from '@affine/core/modules/theme-editor';
import { configureIndexedDBUserspaceStorageProvider } from '@affine/core/modules/userspace';
import { configureBrowserWorkbenchModule } from '@affine/core/modules/workbench';
import {
  configureBrowserWorkspaceFlavours,
  configureIndexedDBWorkspaceEngineStorageProvider,
} from '@affine/core/modules/workspace-engine';
import createEmotionCache from '@affine/core/utils/create-emotion-cache';
import { createI18n, setUpLanguage } from '@affine/i18n';
import { CacheProvider } from '@emotion/react';
import {
  Framework,
  FrameworkRoot,
  getCurrentStore,
  LifecycleService,
  useLiveData,
  useService,
} from '@toeverything/infra';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

const cache = createEmotionCache();

const future = {
  v7_startTransition: true,
} as const;

async function loadLanguage() {
  const i18n = createI18n();
  document.documentElement.lang = i18n.language;

  await setUpLanguage(i18n);
}

let languageLoadingPromise: Promise<void> | null = null;

const framework = new Framework();
configureCommonModules(framework);
configureBrowserWorkbenchModule(framework);
configureLocalStorageStateStorageImpls(framework);
configureBrowserWorkspaceFlavours(framework);
configureIndexedDBWorkspaceEngineStorageProvider(framework);
configureIndexedDBUserspaceStorageProvider(framework);
const frameworkProvider = framework.provider();

// setup application lifecycle events, and emit application start event
window.addEventListener('focus', () => {
  frameworkProvider.get(LifecycleService).applicationFocus();
});
frameworkProvider.get(LifecycleService).applicationStart();

function LoginByTokenGuard({ children }: { children: any }) {
  const authService = useService(AuthService);
  const account = useLiveData(authService.session.account$);
  const fetchService = useService(FetchService);
  const currentUrl = new URL(window.location.href);

  // 思核云登录完带着token跳转回来
  if (currentUrl.searchParams.has('authorizationToken')) {
    fetchService
      .fetch(
        `/api/auth/session?authorizationToken=${currentUrl.searchParams.get('authorizationToken')}`
      )
      .then(() => {
        currentUrl.searchParams.delete('authorizationToken');
        window.location.href = currentUrl.toString();
      })
      .catch(err => {
        console.error(err);
      });
  }

  // 强制要求登录
  if (!account && !currentUrl.searchParams.has('authorizationToken')) {
    let loginRedirectUrl: string = BUILD_CONFIG.loginRedirectUrl;
    fetchService
      .fetch('/api/liulian/env')
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.loginRedirectUrl) {
          loginRedirectUrl = data.loginRedirectUrl as string;
        }
      })
      .catch(
        // ignore
        () => {}
      )
      .finally(() => {
        window.location.href = `${loginRedirectUrl}?redirect_url=${encodeURIComponent(currentUrl.toString())}`;
      });
  }

  return children;
}

export function App() {
  if (!languageLoadingPromise) {
    languageLoadingPromise = loadLanguage().catch(console.error);
  }

  return (
    <Suspense>
      <FrameworkRoot framework={frameworkProvider}>
        <CacheProvider value={cache}>
          <AffineContext store={getCurrentStore()}>
            <Telemetry />
            <CustomThemeModifier />
            <GlobalLoading />
            <LoginByTokenGuard>
              <RouterProvider
                fallbackElement={<AppFallback key="RouterFallback" />}
                router={router}
                future={future}
              />
            </LoginByTokenGuard>
          </AffineContext>
        </CacheProvider>
      </FrameworkRoot>
    </Suspense>
  );
}
